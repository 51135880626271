<template>
  <div class="media-container">
    <div
      v-for="(item, index) in mediaItems"
      :key="index"
      class="media-item"
    >
      <!-- 图片或 GIF -->
      <img
        v-if="item.type === 'image' || item.type === 'gif'"
        v-lazy="item.src"
        alt=""
        class="media-image"
      />
      <!-- 视频封面图 -->
      <div
        v-else-if="item.type === 'videoCover'"
        class="video-cover"
        @click="playVideo(item.videoSrcImport)"
      >
        <img v-lazy="item.src" alt="" class="media-image" />
        <!-- 可以在此添加播放图标 -->
      </div>
    </div>

    <!-- 视频播放器遮罩层 -->
    <div v-if="showVideo" class="video-overlay" ref="videoOverlay">
      <!-- 关闭按钮 -->
      <button class="close-button" @click="closeVideo">×</button>
      <!-- 视频元素 -->
      <video
        ref="videoPlayer"
        :src="currentVideo"
        controls
        autoplay
        playsinline
        webkit-playsinline
        @ended="closeVideo"
      ></video>
    </div>
  </div>
</template>

<script>
import img1 from '@/assets/images/1.jpg';
import img2 from '@/assets/images/2.jpg';
import img3 from '@/assets/images/3.jpg';
import img4 from '@/assets/images/4.jpg';
import img5 from '@/assets/images/5.jpg';
import img6 from '@/assets/images/6.jpg';
import img7 from '@/assets/images/7.jpg';
import img8 from '@/assets/images/8.jpg';
import img9 from '@/assets/images/9.jpg';
import img10 from '@/assets/images/10.jpg';
import img11 from '@/assets/images/11.jpg';
import img12 from '@/assets/images/12.jpg';
import img13 from '@/assets/images/13.jpg';
import img14 from '@/assets/images/14.jpg';
import img15 from '@/assets/images/15.jpg';
import img16 from '@/assets/images/16.jpg';
import img17 from '@/assets/images/17.jpg';
import img18 from '@/assets/images/18.jpg';
import img19 from '@/assets/images/19.jpg';
import img20 from '@/assets/images/20.jpg';
import img21 from '@/assets/images/21.jpg';
import gif22 from '@/assets/images/22.gif';
import gif23 from '@/assets/images/23.gif';
import gif24 from '@/assets/images/24.gif';
import img25 from '@/assets/images/25.jpg'; // 视频封面图
import gif26 from '@/assets/images/26.gif';
import img27 from '@/assets/images/27.jpg'; // 视频封面图
import img28 from '@/assets/images/28.jpg';
import wx from "weixin-js-sdk"
// const wx = require('weixin-js-sdk');
// 视频文件按需加载
export default {
  name: "MediaPage",
  data() {
    return {
      mediaItems: [
        { src: img1, type: "image" },
        { src: img2, type: "image" },
        { src: img3, type: "image" },
        { src: img4, type: "image" },
        { src: img5, type: "image" },
        { src: img6, type: "image" },
        { src: img7, type: "image" },
        { src: img8, type: "image" },
        { src: img9, type: "image" },
        { src: img10, type: "image" },
        { src: img11, type: "image" },
        { src: img12, type: "image" },
        { src: img13, type: "image" },
        { src: img14, type: "image" },
        { src: img15, type: "image" },
        { src: img16, type: "image" },
        { src: img17, type: "image" },
        { src: img18, type: "image" },
        { src: img19, type: "image" },
        { src: img20, type: "image" },
        { src: img21, type: "image" },
        { src: gif22, type: "gif" },
        { src: gif23, type: "gif" },
        { src: gif24, type: "gif" },
        {
          src: img25,
          type: "videoCover",
          videoSrcImport: () => import('@/assets/videos/25.mp4'),
        },
        { src: gif26, type: "gif" },
        {
          src: img27,
          type: "videoCover",
          videoSrcImport: () => import('@/assets/videos/27.mp4'),
        },
        { src: img28, type: "image" },
      ],
      showVideo: false,
      currentVideo: null,
    };
  },
  mounted() {
    // 引入微信 JS-SDK 脚本后，初始化配置
    this.initWeChat();
  },
  methods: {
    async playVideo(videoSrcImport) {
      const videoModule = await videoSrcImport();
      const videoSrc = videoModule.default || videoModule;
      this.currentVideo = videoSrc;
      this.showVideo = true;

      // 添加全屏变化事件监听器
      this.addFullscreenChangeListener();

      // 等待 DOM 更新
      this.$nextTick(() => {
        // 获取 video-overlay 元素
        const videoOverlay = this.$refs.videoOverlay;

        // 请求全屏
        if (videoOverlay.requestFullscreen) {
          videoOverlay.requestFullscreen();
        } else if (videoOverlay.webkitRequestFullscreen) {
          videoOverlay.webkitRequestFullscreen();
        } else if (videoOverlay.mozRequestFullScreen) {
          videoOverlay.mozRequestFullScreen();
        } else if (videoOverlay.msRequestFullscreen) {
          videoOverlay.msRequestFullscreen();
        }
      });
    },
    closeVideo() {
      // 退出全屏
      const documentExitFullscreen =
        document.exitFullscreen ||
        document.webkitExitFullscreen ||
        document.mozCancelFullScreen ||
        document.msExitFullscreen;

      if (documentExitFullscreen) {
        documentExitFullscreen.call(document);
      }

      this.showVideo = false;
      this.currentVideo = null;

      // 移除全屏变化事件监听器
      this.removeFullscreenChangeListener();
    },
    addFullscreenChangeListener() {
      // 监听全屏变化事件
      document.addEventListener('fullscreenchange', this.onFullscreenChange);
      document.addEventListener('webkitfullscreenchange', this.onFullscreenChange);
      document.addEventListener('mozfullscreenchange', this.onFullscreenChange);
      document.addEventListener('MSFullscreenChange', this.onFullscreenChange);
    },
    removeFullscreenChangeListener() {
      // 移除全屏变化事件监听器
      document.removeEventListener('fullscreenchange', this.onFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', this.onFullscreenChange);
      document.removeEventListener('mozfullscreenchange', this.onFullscreenChange);
      document.removeEventListener('MSFullscreenChange', this.onFullscreenChange);
    },
    onFullscreenChange() {
      const isFullscreen =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement;

      if (!isFullscreen) {
        // 如果已经退出全屏，则关闭视频窗口
        this.closeVideo();
      }
    },
    beforeDestroy() {
      // 组件销毁时，移除全屏变化事件监听器
      this.removeFullscreenChangeListener();
    },
    initWeChat() {
        const currentUrl = window.location.href.split('#')[0]; // 获取当前页面 URL（不包括 # 后面的部分）

        var myHeaders = new Headers();
        myHeaders.append("User-Agent", "Apifox/1.0.0 (https://apifox.com)");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "unit_id": "2",
          "uri": currentUrl,
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        fetch(`https://qfapi.91gzt.com/app/login/getJsConfig`,requestOptions)
          .then((response) => response.json())
          .then((config) => {
            if (config.code !== 1000) {
              throw new Error(config.message || '获取微信配置失败');
            }

            const { app_id, timestamp, nonce_str, signature } = config.data;

            wx.config({
              debug: false, // 发布时设为 false
              appId: app_id,
              timestamp: timestamp,
              nonceStr: nonce_str,
              signature: signature,
              jsApiList: [
                'updateAppMessageShareData',
                'updateTimelineShareData',
              ], // 需要调用的 JS 接口列表
            });

            wx.ready(() => {
              const shareData = {
                title: '贾维思智能科技-你的AI技术部', // 分享标题
                desc: '我们专注于为室内空间的AI化提供软件、硬件定制以及整体解决方案', // 分享描述
                link: currentUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS安全域名一致
                imgUrl: 'https://qianfengai.oss-cn-hangzhou.aliyuncs.com/source/172689029080807dxf4xje9', // 分享图标
                success: () => {
                  console.log('分享成功');
                },
                cancel: () => {
                  console.log('分享取消');
                },
              };

              // 分享给朋友
              wx.updateAppMessageShareData(shareData);

              // 分享到朋友圈
              wx.updateTimelineShareData({
                title: shareData.title, // 朋友圈不支持 desc
                link: shareData.link,
                imgUrl: shareData.imgUrl,
                success: () => {
                  console.log('分享到朋友圈成功');
                },
                cancel: () => {
                  console.log('分享到朋友圈取消');
                },
              });
            });

            wx.error((res) => {
              console.error('微信 JS-SDK 配置失败：', res);
            });
          })
          .catch((error) => {
            console.error('获取微信配置失败：', error);
          });
    },
  },
};
</script>

<style scoped>
.media-container {
  width: 100%;
  overflow: hidden;
}

.media-item {
  width: 100%;
}

.media-image {
  width: 100%;
  display: block;
}

.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000; /* 设置为黑色背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.video-overlay video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #000;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1001;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
}

.video-cover {
  position: relative;
}
</style>