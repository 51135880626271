<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* 重置样式，确保页面占满全屏 */
html, body, #app {
  margin: 0;
  padding: 0;
  height: 100%;
}
</style>