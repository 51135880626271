import Vue from 'vue';
import Router from 'vue-router';
import MediaPage from '@/components/MediaPage.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'MediaPage',
      component: MediaPage,
    },
  ],
});